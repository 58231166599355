import Skeleton from '@carbonfact/ui-components/src/Skeleton';
import { Combobox, ComboboxButton, ComboboxOptions } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import { useCallback, useMemo, useState } from 'react';

import { uniq } from 'lodash';
import {
  DropdownOptionPrimitive,
  type DropdownOptionPrimitiveProps,
} from '../../primitives';
import { AutocompleteInputPrimitive } from '../../primitives/AutoCompleteInputPrimitive';
import { DropdownButtonPrimitive } from '../../primitives/DropdownButtonPrimitive';
import { DropdownOptionGroupPrimitive } from '../../primitives/DropdownOptionGroupPrimitive';
import { DropdownOptionsScrollerPrimitive } from '../../primitives/DropdownOptionsScrollerPrimitive';
import { PlaceholderPrimitive } from '../../primitives/PlaceholderPrimitive';

type OptionType<T> = DropdownOptionPrimitiveProps<T>['option'];

export type DropdownDefaultProps<T extends React.Key = string> = {
  label?: string;
  options: OptionType<T>[];
  loading?: boolean;
  value: T | null;
  disabled?: boolean;
  backgroundColor?: string;
  onChange: (val: T) => void;
  className?: string;
  height?: string;
  placeholder?: string;
  maxDisplaySize?: number;
  autocomplete?: boolean;
  optionsDisplayType?: 'relative' | 'absolute';
  showArrow?: boolean;
  align?: 'left' | 'right';
};
export const Dropdown = <T extends React.Key = string>({
  label,
  options,
  disabled,
  backgroundColor,
  className = '',
  loading,
  placeholder,
  value,
  height = 'h-9',
  maxDisplaySize = 100,
  showArrow = true,
  // Should the options be displayed in an absolute or relative container
  optionsDisplayType = 'absolute',
  onChange,
  autocomplete,
  align = 'left',
}: DropdownDefaultProps<T>) => {
  const t = useTranslations();
  const [autocompleteSearchTerm, setAutocompleteSearchTerm] = useState('');
  const [hoveredOption, setHoveredOption] = useState<OptionType<T> | null>(
    null,
  );

  let bgColor = 'bg-white';

  if (backgroundColor) {
    bgColor = backgroundColor;
  }
  if (!placeholder) {
    placeholder = t('Dropdown.selectOption');
  }
  const handleChange = useCallback(
    (newPickedOption: OptionType<T>) => {
      onChange(newPickedOption?.value);
    },
    [onChange],
  );

  const { filteredOptions, filteredResultsLength } = useMemo(() => {
    if (!autocomplete) {
      return {
        filteredOptions: options,
        filteredResultsLength: options.length,
      };
    }

    let filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(autocompleteSearchTerm.toLowerCase()),
    );

    const filteredResultsLength = filteredOptions.length;

    if (filteredOptions.length > maxDisplaySize) {
      filteredOptions = filteredOptions.slice(0, maxDisplaySize);
    }

    return { filteredOptions, filteredResultsLength };
  }, [options, autocomplete, autocompleteSearchTerm, maxDisplaySize]);

  const valueOption = options.find((anOption) => anOption.value === value);
  const isDisabled = disabled || options.length < 1;

  // Find option groups to display
  const optionGroups: string[] = useMemo(() => {
    return uniq(
      options
        .map((option) => option.groupLabel)
        .filter((label) => label !== undefined),
    );
  }, [options]);

  if (loading) {
    return <Skeleton height={height} width="min-w-[90px] w-full" />;
  }

  if (options.length === 0) {
    return null;
  }

  const containerBoxClass =
    'bg-white p-2 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none';

  return (
    <Combobox
      disabled={isDisabled}
      value={valueOption || null}
      onChange={handleChange}
      onClose={() => setAutocompleteSearchTerm('')}
    >
      {({ open }) => (
        <div
          className={classNames(
            'relative border-carbon-100 w-fit transition-all',
            height,
            className,
          )}
        >
          {(!autocomplete || (autocomplete && !open)) && (
            <ComboboxButton
              className={classNames(
                'rounded-md border-[1.5px] box-border border-gray-200 hover:border-gray-300 items-center px-2 focus:outline-none h-full w-full justify-between flex flex-row',
                bgColor,
              )}
            >
              <DropdownButtonPrimitive
                showArrow={showArrow}
                disabled={isDisabled}
                prefix={label}
              >
                {!value && <PlaceholderPrimitive text={placeholder} />}
                {value && (
                  <p className="text-sm font-medium whitespace-nowrap overflow-hidden text-ellipsis">
                    {valueOption?.label}
                  </p>
                )}
              </DropdownButtonPrimitive>
            </ComboboxButton>
          )}

          {open && (
            <>
              <ComboboxOptions
                static
                className={classNames(
                  'absolute p-2 z-50 flex flex-row mb-20 min-w-full origin-top-right max-h-[50vh] overflow-y-auto  gap-2 ',
                  align === 'left' ? 'left-0' : 'right-0',
                  optionsDisplayType === 'absolute'
                    ? 'absolute'
                    : 'relative top-[-0.5rem] left-[-0.5rem]',
                )}
              >
                <div className={containerBoxClass}>
                  {autocomplete && (
                    <AutocompleteInputPrimitive
                      placeholder={placeholder}
                      selectedText={valueOption?.label}
                      label={label}
                      onChange={setAutocompleteSearchTerm}
                    />
                  )}
                  {filteredOptions.length === 0 && (
                    <div className="p-1 text-sm text-carbon-500">
                      {t('Dropdown.noResultFound')}
                    </div>
                  )}
                  {autocomplete &&
                    filteredOptions.length > 0 &&
                    filteredOptions.length !== filteredResultsLength && (
                      <div className="p-1 text-xs text-carbon-500 font-medium border-b border-carbon-100 flex pb-2">
                        {t('Dropdown.displayingXOptions', {
                          count: filteredOptions.length,
                          total: filteredResultsLength,
                        })}
                      </div>
                    )}
                  <DropdownOptionsScrollerPrimitive>
                    {
                      // Show options by group
                      optionGroups.map((groupLabel) => (
                        <DropdownOptionGroupPrimitive
                          label={groupLabel}
                          key={groupLabel}
                        >
                          {filteredOptions
                            .filter(
                              (option) => option.groupLabel === groupLabel,
                            )
                            .map((option) => (
                              <DropdownOptionPrimitive
                                key={option.value}
                                option={option}
                                onHover={(val) => setHoveredOption(val)}
                                selected={option.value === value}
                              >
                                {option.label}
                              </DropdownOptionPrimitive>
                            ))}
                        </DropdownOptionGroupPrimitive>
                      ))
                    }
                    {
                      // Show group-less options
                      filteredOptions
                        .filter((option) => !option.groupLabel)
                        .map((option) => (
                          <DropdownOptionPrimitive
                            key={option.value}
                            option={option}
                            onHover={(val) => setHoveredOption(val)}
                            selected={option.value === value}
                          >
                            {option.label}
                          </DropdownOptionPrimitive>
                        ))
                    }
                  </DropdownOptionsScrollerPrimitive>
                </div>
              </ComboboxOptions>
              {hoveredOption && (
                <div
                  className={classNames(
                    containerBoxClass,
                    'absolute left-full ml-[-4px] top-0 text-gray-900 text-sm w-64 z-50 whitespace-normal p-4',
                  )}
                >
                  {hoveredOption.hoverContent}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </Combobox>
  );
};
