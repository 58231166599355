'use client';

import type { ReactElement } from 'react';

type SliderProps = {
  onChange: (s: number) => void;
  value: number;
  disabled?: boolean;
  max?: number;
  min?: number;
  step?: number;
};

export function Slider({
  onChange,
  value,
  disabled,
  max,
  min,
  step,
}: SliderProps): ReactElement {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const val = +e.target.value;
    onChange(val);
  };
  return (
    <div className="w-full">
      <input
        type="range"
        className="w-full h-1 border-none rounded accent-gray-500"
        value={value}
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
      />
    </div>
  );
}
